.loader {
  &.xs {
    height: 23px;
    width: 23px;
  }
  &.sm {
    height: 40px;
    width: 40px;
  }

  &.md {
    height: 100px;
    width: 100px;
  }

  &.lg {
    height: 200px;
    width: 200px;
  }

  &.white {
    svg path,
    svg rect {
      fill: white;
    }
  }
}
