$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #aaa !default;
$gray-600: #777 !default;
$gray-700: #495057 !default;
$gray-800: #333 !default;
$gray-900: #222 !default;
$black: #000 !default;
$blue: #eb6864 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #f57a00 !default;
$orange: #fd7e14 !default;
$yellow: #f5e625 !default;
$green: #22b24c !default;
$teal: #20c997 !default;
$cyan: #369 !default;
$primary: $blue !default;
$secondary: $gray-500 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

// Required
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/transitions';

@import '../../node_modules/bootstrap/scss/alert';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/card';
@import '../../node_modules/bootstrap/scss/close';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
// @import '../../node_modules/bootstrap/scss/print';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/utilities';

.bg-dark {
  background-color: #000 !important;
}

.bg-light {
  background-color: $white !important;
  color: $black;
  border: 1px solid $gray-200;

  &.navbar-fixed-top {
    border-width: 0 0 1px 0;
  }

  &.navbar-fixed-bottom {
    border-width: 1px 0 0 0;
  }
}

.navbar {
  font-size: 18px;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
}

.navbar-brand {
  padding-top: 0.5rem;
  font-size: inherit;
  font-weight: $headings-font-weight;
  text-transform: uppercase;
}

// Buttons =====================================================================

.btn {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;

  &-secondary,
  &-warning {
    color: $white;
  }
}

// Typography ==================================================================

// Tables ======================================================================

// Forms =======================================================================

// Navs ========================================================================

.pagination {
  a:hover {
    text-decoration: none;
  }
}

// Indicators ==================================================================

// Progress bars ===============================================================

// Containers ==================================================================
