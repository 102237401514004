@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.AppNavbar {
  :global(.dropdown-item) {
    &:hover {
      background-color: transparent;
    }

    @include media-breakpoint-up(md) {
      button {
        width: 246px !important;
      }
    }
  }
}
