@import './_themes/journal.scss';

//------------------app custom

body {
  padding-top: 6.5rem;
}

main {
  min-height: 80vh;
}
//fix odd form behavior with react bootstrap
.invalid-feedback:empty,
.valid-feedback:empty {
  display: none !important;
}

.btn-link-exact {
  outline: none !important;
  padding: 0 !important;
  border: 0 !important;
  box-sizing: none !important;
  background-color: transparent !important;
  text-align: left !important;
  line-height: 1.5rem !important;
}

.btn-icon {
  line-height: 0;
  margin-right: 0.35rem;
  svg {
    max-height: 15px;
    max-width: 15px;
  }
}
