.SiteFooter {
  li {
    &:after {
      content: '|';
    }
    &:last-of-type {
      &:after {
        content: '';
      }
    }
  }
}
